$container-max-widths: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

// Typography
$font-family-base: 'Helvetica Neue';

// Colors
$primary: #1d8975;
$success: #1d8975;
$warning: #fca33a;
$light: #ffffff;
$neutral: #f6f6f6;
$neutralPrimary: #e9f6f3;

// Borders
$border-radius: 0.5rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.75rem;
$border-radius-xl: 1rem;
$border-radius-xxl: 2rem;

// Forms
$form-label-margin-bottom: 0.3rem;
$form-label-font-size: 0.75rem;
$form-label-font-weight: 500;
$input-btn-focus-box-shadow: none;
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;
$form-check-input-focus-box-shadow: none;

// Misc
$headerHeight: 4rem;
