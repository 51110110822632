header {
    position: fixed;
    height: $headerHeight;
    z-index: 1000;
}

.offcanvas-header {
    height: $headerHeight;
}

.login-logo img {
    width: 4.2rem;
    height: auto;
}

.logo {
    position: relative;
    top: -2px;
}

.logo img {
    width: 4.2rem;
    height: auto;
}

.header-avatar {
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 2rem;
    margin-right: 0.5rem;
}

.artist-image {
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 2.5rem;
    margin-right: 0.5rem;
}

.avatar {
    height: 5rem;
    width: 5rem;
    border-radius: 5rem;
}

.poster {
    height: 5.2rem;
    width: 5.2rem;
    border-radius: $border-radius;
    object-fit: cover;
}

.btn-close {
    padding: 0;
    box-shadow: none !important;
    &:hover,
    &:focus,
    &:active {
        box-shadow: none !important;
    }
}

.content-wrapper {
    margin-top: $headerHeight;
    padding: 2rem 0;
}

.block {
    background-color: $light;
    padding: 0.8rem;
    border-radius: $border-radius-xl;
    min-height: 10rem;
}

aside {
    position: sticky;
    top: $headerHeight + 2rem;
}

.aside-body ul li a {
    padding: 0.75rem 1rem;
    display: inline-block;
    width: 100%;
    border-radius: $border-radius-xl;
}

.aside-body ul li a.active {
    background-color: $neutralPrimary;
    font-weight: 700;
}

.profile-overview table td,
.profile-overview table th {
    padding: 0.5rem 0;
}

.profile-overview table th {
    width: 50%;
    font-size: 0.9rem;
}

.info-box {
    background-color: $neutralPrimary;
}

.mobile-aside-content {
    overflow-x: scroll;
}

.mobile-aside-content::-webkit-scrollbar {
    display: none;
}

.block-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-xl;
    background-color: rgba($light, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-page {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: $border-radius-sm;
    display: flex;
    align-items: center;
    justify-content: center;;
}

.react-select.is-invalid > div:first-of-type {
    border: 1px solid $danger;
}

.react-datepicker-wrapper {
    width: 100%;
}

.list-item:hover {
    background-color: $neutralPrimary;
}

.qr-user-image {
    width: 10rem;
    height: auto;
    border-radius: 10rem;
}

.partner-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: $border-radius;
}

@media (min-width: 768px) {
    
    .profile-overview table th {
        width: 30%;
        font-size: 1rem;
    }

    .block {
        padding: 1.5rem;
    }
}