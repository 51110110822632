html,
body {
    width: 100%;
    max-width: 100%;
    background-color: $neutral;
}

.btn-primary {
    @include button-variant(
        $primary,
        $primary,
        $light,
        $primary,
        $primary,
        $light,
        $primary,
        $primary,
        $light,
        $primary,
        $primary,
        $light
    );
}

.text-monospace {
    font-family: $font-family-monospace;
}

.btn-primary {
    color: $light;
    &:hover,
    &:focus,
    &:active {
        color: $light;
    }
}

.form-control.is-invalid {
    background-image: none !important;
    box-shadow: none !important;
}

.form-select.is-invalid {
    box-shadow: none !important;
    --bs-form-select-bg-icon: none !important;
}

.invalid-feedback {
    font-size: 0.75rem !important;
}

.icon-btn,
.icon-btn:hover,
.icon-btn:focus,
.icon-btn:active {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none;
    outline: none;
}

a,
.btn-link {
    color: $dark;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
        color: $dark;
        text-decoration: none;
    }
}